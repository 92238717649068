.footer {
    background: $black;
    line-height: 1.9;
    padding: 2.4rem 0;
    color: $white;
    font-size: 1.071rem;
    position: relative;
    &__title {
        color: #818181;
        font-size: 1.429rem;
        font-weight: normal;
        line-height: 1.9;
        text-transform: uppercase;
    }
    &__facebook {
        font-size: 1.8rem;
    }
    a {
        color: $white;
        &:hover {
            text-decoration: underline;
        }
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            padding: .5rem 0 .5rem 2rem;
            position: relative;
        }
        i {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            opacity: .2;
            font-size: 1.429rem;
        }
    }
    &__ue {
        margin-top: 10%;
    }
    &::after {
        content: '';
        display: block;
        height: 4px;
        background: linear-gradient(to right, $primary 0%, $secondary 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    @include media-breakpoint-up(lg) {
        padding: 3.4rem 0 4.4rem;
        &__title {
            margin-bottom: 2rem;
        }
        [class^='col-']:not(:last-child) {
            position: relative;
            &::after {
                content: '';
                background: rgba($white, .3);
                height: 80%;
                width: 1px;
                position: absolute;
                right: 0; top: 60%;
                transform: translateY(-50%);
            }
        }
        &__list {
            li {
                padding: .5rem 0;
            }
            i {
                position: absolute;
                left: -40px;
                top: 50%;
                transform: translateY(-50%);
                opacity: .2;
                font-size: 1.7rem;
            }
            &--right {
                i {
                    left: auto;
                    right: -40px;
                }
            }
        }
    }
}
