.header {
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    z-index: 2;
    &::before {
        content: '';
        position: absolute;
        top: 0; bottom: 0; left: -100%;
        width: 100%;
        background: $white;
    }
    &__cont {
        background: $white;
        position: relative;
        z-index: 2;
    }
    &__fb {
        font-size: 2rem;
    }
    .navbar__mob-cont {
        background: rgba($black, .85);
        width: 100%;
        padding: 10px 20px;
        .header__logo {
            a { display: block; }
            img {
                width: 40px;
            }
        }
    }
    .navbar-dark .navbar-nav .nav-link {
        text-transform: uppercase;
        font-size: .8571rem;
        color: $white;
    }
    .nav-item {
        position: relative;
    }
    .navbar-toggler {
        transform: scale(.82);
        transform-origin: right;
    }

    .header__line {
        height: 1px;
        width: 100%;
        max-width: 374px;
        background: $secondary;
    }

    .navbar-collapse {
        background: rgba($black, .85);
        padding: 0 20px;
        text-align: center;
    }

    @include media-breakpoint-up(lg) {
        position: absolute;
        padding: 0 20px;
        .navbar-nav {
            flex-shrink: 0;
        }
        .nav-link {
            margin: 29px 0;
            padding-right: 1.4rem !important;
            padding-left: 1.4rem !important;
            &:hover {
                color: $primary !important;
            }
        }
        .nav-item {
            height: 100%;
            margin: 0 .5rem;
            &.active {

            }
        }

        .navbar-collapse {
            height: 100px;
            background: none;
            padding: 0;
            text-align: left;
        }
        .navbar__mob-cont {
            background: none;
        }
    }
}


