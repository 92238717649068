.banner {
    overflow: hidden;
    position: relative;
    background-position: center;
    background-size: cover;
    padding-top: 120px;
    &--small {
        height: 374px;
    }
    &--slider {
        //padding-top: 82%;
        height: 400px;
    }

    //&::before {
    //    content: '';
    //    background: rgba($black, .46);
    //    position: absolute;
    //    left: 0; top: 0; bottom: 0; right: 0;
    //}
    .container-fluid {
        position: relative;
        height: 100%;
    }
    &__caption {
        color: $white;
        height: 100%;
        &-desc {
            font-size: 0.8571rem;
        }
        &-logo {
            max-width: 230px;
        }
    }

    &__people-col {
        position: static;
    }
    &__people-img {
        max-width: 39vw;
        position: absolute;
        bottom: 0;
    }

    &__people-name {
        font-size: 1.429rem;
        line-height: 1;
        word-spacing: 100vw;
        text-transform: uppercase;
        color: $primary;
    }
    &__people-position {
        font-size: 1.071rem;
        text-transform: uppercase;
        display: inline-block;
    }
    &__quote {
        color: $primary;
        text-transform: uppercase;
        position: absolute;
        right: 4rem; bottom: 0;
        &-text {
            font-size: 1.3rem;
            line-height: 1;
        }
        &-author {
            font-size: .8571rem;
        }
        &::before, &::after {
            content: "\6d";
            position: absolute;
            color: rgba($white, .2);
            font-size: 3rem;
            @extend [class^="icon-"];
            margin-top: 0;

        }
        &::before {
            top: -3rem;
            left: -3.5rem;
            transform: rotate3d(0, 0, 1, 180deg);
        }
        &::after {
            bottom: -3rem;
            right: -3.5rem;
        }
    }
    .swiper-container {
        position: absolute;
        left: 0; top: 0; right: 0; bottom: 0;
    }
    .swiper-slide {
        background-position: center;
        background-size: cover;
        padding-top: 70px;
        padding-bottom: 60px;
        //&::before {
        //    content: '';
        //    background: rgba($black, .46);
        //    position: absolute;
        //    left: 0; top: 0; bottom: 0; right: 0;
        //}
        &--people {
            padding-top: 80px;
            padding-bottom: 0;
        }
    }
    .swiper-scroll-down {
        position: absolute;
        bottom: 70px;
        left: 0; right: 0;
        z-index: 2;
        text-align: center;
        font-size: 2rem;
        a { color: $white; }
        &--bottom {
            bottom: 10px;
        }
    }
    @include media-breakpoint-up(md) {
        &--slider {
            height: 520px;
        }
        &__quote {
            &-text {
                font-size: 1.857rem;
                line-height: 1;
            }
        }
        &__caption {
            &-desc {
                font-size: 1rem;
            }
            &-logo {
                max-width: 100%;
            }
        }
        &__people-info {
            position: absolute;
            right: 20px;
            top: 32%;
        }
        &__people-col {
            //position: relative;
            display: flex;
            align-items: flex-end;
        }
        .swiper-slide {
            padding-top: 50px;
            padding-bottom: 120px;
            &--people {
                padding-bottom: 0;
                .container-md, .banner__caption, .row { height: 100%; }
            }
        }
        &__people-img {
            position: static;
        }
        &__people-name {
            font-size: 2.714rem;
        }
        &__people-btn {
            position: absolute;
            right: 20px;
            bottom: 20%;
        }
        .swiper-scroll-down {
            &--bottom {
                bottom: 70px;
            }
        }
    }
    @include media-breakpoint-up(lg) {



        &__people-name {
            font-size: 3.929rem;
        }
        &--slider {
           // padding-top: 42%;
            height: 100vh;
        }
        .swiper-slide {
            padding-top: 120px;
        }
    }
}
