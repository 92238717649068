.heading {
    background: #e8e8e8;

    &__cont {
        background-position: center right;
        background-repeat: no-repeat;
        padding: 1.8rem 0;
    }

    &__content {
        position: relative;
        padding-left: 50px;
        &::before {
            content: '';
            width: 27px;
            height: 100%;
            position: absolute;
            left: 0; top: 51%;
            transform: translateY(-50%);
        }
    }
    &__icon {

    }
    &__title {
        font-size: 2rem;
        font-weight: 800;
        text-transform: uppercase;
        line-height: .9;
    }
    &__desc {
        line-height: 1;
    }
    @include media-breakpoint-up(md) {
        &__cont {
            padding: 3.719rem 0;
        }
        &__content {
            &::before {
                height: 140%;
            }
        }
        &__title {
            font-size: 3.125rem;
        }
    }
    @include media-breakpoint-up(lg) {
        margin-top: 5rem;
    }
}

