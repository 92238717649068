html {
    font-size: 14px;
}

.container-fluid {
    max-width: 1060px;
}

.container-lg {
    max-width: 1830px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.container-mdlg {
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.container-md {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

a:hover {
    text-decoration: none;
}

.cms-content {
    h1,h2,h3,h4,h5,h6 { font-weight: 800; }
    h2 { font-size: 1.5rem;  }
    h3 { font-size: 1.3rem;  }
    h4 { font-size: 1.1rem;  }
}

.text--lg {
    @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
    }
}

.form-control {
    &:focus {
        border-color: $primary;
    }
}

.btn {
    &-line {
        padding: .5rem 1.75rem;
        background: none;
        border: none;
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;
        text-transform: uppercase;
        color: $white;
        font-size: 1.071rem;
        cursor: pointer;
        &:focus, &:active {
            box-shadow: none;
        }
        &--black {
            color: $black;
        }
        &--white {
            &, &:hover {
                color: $white;
            }
        }
    }
}

.mt-6 { margin-top: 4vw; }
.mb-6 { margin-bottom: 4vw; }

.section {
    padding-top: 1.429rem;
    padding-bottom: 1.429rem;
    overflow: hidden;
    &--dark {
        color: $white;
    }
    &--bg-parallax {
        background-attachment: fixed;
        background-size: cover;
    }
    &--bg-gray {
        background: #f6f6f6;
    }
    &--bg-icon {
        background-repeat: no-repeat;
        background-position: center left;
        &-right {
            background-position: center right;
        }
    }
    &__map {
        padding: 0;
        height: 380px;
    }
    &__heading {
        text-align: center;
        margin-bottom: 1rem;
    }
    &__title {
        font-size: 2.286rem;
        text-transform: uppercase;
    }
    &__icon {
        display: inline-block;
        position: relative;
        font-size: 3.5rem;

        &::before, &::after {
            content: '';
            height: 1px;
            width: 400%;
            max-width: 92px;
            background: $primary;
            position: absolute;
            top: 50%;
        }
        &::before {
            left: calc(-100% - 75px);
        }
        &::after {
            right: calc(-100% - 75px);
        }
    }
    &__text {
        &--md {
            @extend .fs-17;
        }
        &--lg {
            @extend .fs-22;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                position: relative;
                padding: .4rem 0 .4rem 1.4rem;
                &:before {
                    content: "\66";
                    @extend [class^="icon-"];
                    color: $secondary;
                    font-size: .8rem !important;
                    position: absolute;
                    left: 0;
                    margin-top: .4rem !important;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            &.text-lg-right {
                ul li {
                    padding-left: 0;
                    padding-right: 1.4rem;
                    &:before {
                        left: auto;
                        right: 0;
                        margin-top: .5rem !important;
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        padding-top: 5%;
        padding-bottom: 5%;
        &__heading {
            margin-bottom: 3rem;
        }
    }
}

.fs-17 {
    @include media-breakpoint-up(md) {
        font-size: 1.214rem;
    }
}
.fs-22 {
    @include media-breakpoint-up(md) {
        font-size: 1.571rem;
    }
}

.lh-2 {
    @include media-breakpoint-up(md) {
        line-height: 2;
    }
}