@charset "UTF-8";

@font-face {
    font-family: "kanc";
    src:url("../fonts/icons/kanc.eot");
    src:url("../fonts/icons/kanc.eot?#iefix") format("embedded-opentype"),
    url("../fonts/icons/kanc.woff") format("woff"),
    url("../fonts/icons/kanc.ttf") format("truetype"),
    url("../fonts/icons/kanc.svg#kanc") format("svg");
    font-weight: normal;
    font-style: normal;

}

[class^="icon-"] {
    display: inline-block;
    font: normal normal normal 14px/1 kanc;
    font-size: inherit;
    text-rendering: auto;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //vertical-align: middle;
    margin-top: 1rem;
}

.icon-fb01:before {
    content: "\61";
}
.icon-fb02:before {
    content: "\62";
}
.icon-info:before {
    content: "\63";
}
.icon-law:before {
    content: "\64";
}
.icon-loc:before {
    content: "\65";
}
.icon-logo01:before {
    content: "\66";
}
.icon-logo02:before {
    content: "\67";
}
.icon-mail:before {
    content: "\68";
}
.icon-phone:before {
    content: "\69";
}
.icon-scrol:before {
    content: "\6a";
}
.icon-team:before {
    content: "\6b";
}
.icon-wordpress:before {
    content: "\6c";
}
.icon-quote:before {
    content: "\6d";
}