.team {
    &__position {
        font-size: 1.071rem;
        text-transform: uppercase;
    }
    &__name {
        font-size: 2.286rem;
        color: $primary;
        text-transform: uppercase;
        word-spacing: 100vw;
    }
    &__desc {
        font-size: 1.143rem;
        line-height: 2;
        text-align: left;
        p {
            @extend .fs-17;
        }
    }
    &__btn {
        position: relative;
        margin-top: 1rem;
    }

    @include media-breakpoint-up(lg) {
        &__btn {
            margin-top: -8rem;
        }
    }
}
