.contact {
    &__map {
        height: 330px;
    }
    &__title {
        text-transform: uppercase;
        color: $primary;
        font-size: 1.929rem;
        margin-bottom: 2rem;
    }
    &__text {
        font-size: 1.5rem;
    }
    &__form {
        max-width: 500px;
    }
    textarea {
        min-height: 165px;
    }

    @include media-breakpoint-up(lg) {
        &__map {
            position: absolute !important;
            left: 50%;
            right: 0;
            height: 890px;
            &-col {
                position: static;
            }
        }
    }

}

