.services {
    &__logo {
        display: none;
    }
    @include media-breakpoint-up(lg) {
        &__logo {
            display: inline-block;
            margin-bottom: -10%;
        }
        &__btn {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
        }
    }
}
